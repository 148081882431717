import React, { useMemo } from 'react';
import { Box } from '@chakra-ui/react';
import Select from 'react-select';

import Project from '@projectTypes/Project';
import { useReactSelectStyles } from './useReactSelectStyles';

interface ProjectSelectProps {
  projects: Project[];
  selectedProjectId: string | undefined;
  onSelect: (_projectId: string) => void;
  hasMultiYear: boolean;
}

const ProjectSelect = (props: ProjectSelectProps) => {
  const { projects, selectedProjectId, onSelect, hasMultiYear } = props;
  const options = useMemo(
    () =>
      Object.entries(
        projects.reduce((groups, project) => {
          const key = `${project.yearId}-${project.sectionId}-${project.sectionName}`;
          if (!groups[key]) {
            groups[key] = [];
          }

          groups[key].push(project);
          return groups;
        }, {} as Record<string, Project[]>),
      ).map(([key, options]) => {
        const [year, , name] = key.split('-');
        const label = `${year} - ${name}`;

        return {
          label,
          options,
        };
      }),
    [projects],
  );

  const selectedProject = useMemo(() => {
    const p = projects.find((opt) => opt.id === selectedProjectId);
    if (!p) {
      return undefined;
    }

    return {
      ...p,
      name: hasMultiYear ? `${p.yearId} - ${p.name}` : p.name,
    };
  }, [selectedProjectId, projects]);

  // TODO: USE chakra-form for this
  const themed = useReactSelectStyles();
  const styles = useMemo(
    () => ({
      ...themed,
      styles: {
        ...themed.styles,
        control: (base, props) => ({
          ...base,
          ...themed.styles?.control(base, props),
          height: 32,
          minHeight: 32,
        }),
        container: (base, props) => ({
          ...base,
          ...themed.styles?.container(base, props),
          width: '180px',
        }),
        input: (base) => ({ ...base, margin: 0 }),
        indicatorSeparator: () => ({ display: 'none' }),
        indicatorsContainer: (base) => ({ ...base, height: 32 }),
      },
    }),
    [themed],
  );

  return (
    <Box h="32px">
      <Select<Project, false>
        {...styles}
        options={options}
        getOptionLabel={(option) => option.name}
        getOptionValue={(option) => option.id}
        value={selectedProject}
        onChange={(opt) => {
          onSelect(opt.id);
        }}
      />
    </Box>
  );
};

export default ProjectSelect;
