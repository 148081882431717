import React from 'react';
import { useSelector } from 'react-redux';
import {
  HStack,
  IconButton,
  Menu,
  MenuButton,
  StackProps,
  Tooltip,
} from '@chakra-ui/react';
import { RepeatIcon } from '@chakra-ui/icons';

import useDispatch from '@hooks/useDispatch';
import {
  projectsSelector,
  selectedProjectSelector,
  stateSelector,
} from '@selectors';
import { selectProject as selectProjectAction } from '@slices/projectSlices';
import { getProjectFiles } from '@slices/fileSlices';
import ProjectSelect from './ProjectSelect';
import MainMenu from '../Editor/CodeEditor/MainMenu';
import config from '@config';

interface ProjectNavigationProps {
  outerProps?: StackProps;
}
const ProjectNavigation: React.FC<ProjectNavigationProps> = (props) => {
  const { outerProps } = props;

  const dispatch = useDispatch();
  const state = useSelector(stateSelector);
  const selectedProject = useSelector(selectedProjectSelector);
  const { hasMultiYear } = useSelector(projectsSelector);

  const selectProject = (projectId: string | undefined) => {
    if (projectId) {
      dispatch(selectProjectAction(projectId));
    }
  };

  return (
    <HStack
      width={config.sidebarWidth}
      backgroundColor={'#181818'}
      flexDirection="row"
      {...outerProps}
    >
      <ProjectSelect
        // FIXME: Correctly type that /projects and /projects/:id are not the same
        hasMultiYear={hasMultiYear}
        projects={state.project.projects as any}
        selectedProjectId={selectedProject?.id}
        onSelect={selectProject}
      />
      <MainMenu path={''} />
    </HStack>
  );
};

export default ProjectNavigation;
