import React, { useState, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useDropzone } from 'react-dropzone';
import { useConfirm } from 'chakra-confirm';

import {
  Button,
  FormLabel,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Image,
  HStack,
  useBoolean,
  Box,
  CloseButton,
  Text,
  Flex,
  Skeleton,
  Toast,
  useToast,
} from '@chakra-ui/react';
import File from '@projectTypes/File';
import { createNewFile } from '@slices/fileSlices';
import useDispatch from '@hooks/useDispatch';
import { projectFilesSelector, selectedProjectSelector } from '@/selectors';
import config from '@config';
import { error } from 'console';

interface ImageUploadModalProps {
  state: boolean;
  displayedFile: File;
  path?: string;
  onClose: () => void;
}

const ImageUploadModal = (props: ImageUploadModalProps) => {
  const { displayedFile, path, state, onClose } = props;
  const dispatch = useDispatch();
  const selectedProject = useSelector(selectedProjectSelector);
  const projectFiles = useSelector(projectFilesSelector);
  const [duplicate, setDuplicate] = useState(false);
  const [imageUrl, setImageUrl] = useState('');
  const [imageName, setImageName] = useState('');
  const [imageFormat, setImageFormat] = useState('');

  function imageModalClose() {
    imageUrl ? confirmModalClose(false) : onClose();
  }

  const confirmModal = useConfirm({
    title: 'Are you sure you want to exit?',
    body: 'The selected image will be discarded!',
  });

  const confirmModalClose = async (close: boolean) => {
    const ok = await confirmModal();

    if (ok) {
      setImageUrl('');
      if (close) {
        onClose();
      }
    }
  };

  const handleKeyDown = (path) => {
    onClose();
    let newImageName = imageName + '.' + imageFormat;

    let projectFileNames = projectFiles.map((el) => {
      return el.name;
    });

    if (!projectFileNames.includes(newImageName)) {
      dispatch(
        createNewFile({
          projectId: selectedProject.id,
          content: imageUrl,
          name: newImageName,
          contentType: imageFormat,
          path: path,
        }),
      );
    } else {
      toast({
        title: 'File duplicate',
        status: 'error',
      });
    }

    setDuplicate(false);
    setImageUrl('');
  };

  const toast = useToast();
  const [loading, setLoading] = useState(false);
  const [changeImage, { toggle, off }] = useBoolean(false);

  window.addEventListener(
    'beforeunload',
    (e) => {
      if (loading) {
        e.preventDefault();
      }
    },
    { capture: true },
  );

  const onDrop = useCallback(async (acceptedFiles) => {
    if (acceptedFiles.length === 0) {
      return;
    }

    setLoading(true);
    const formData = new FormData();
    formData.append('file', acceptedFiles[0]);
    formData.append('upload_preset', 'STEMIimageUpload');
    const options = {
      method: 'POST',
      body: formData,
    };

    try {
      const res = await fetch(
        'https://api-eu.cloudinary.com/v1_1/dv4yn127p/image/upload',
        options,
      );
      const data = await res.json();
      setImageUrl(data.secure_url);
      setImageName(data.original_filename);
      setImageFormat(data.format);
    } catch (error) {
      // eslint-disable-next-line no-alert
      alert(error);
    } finally {
      setLoading(false);
      off();
    }
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: config.allowedImageUploadTypesString,
  });

  return (
    <>
      <Modal
        isOpen={state}
        onClose={() => {
          imageModalClose();
        }}
        size="sm"
      >
        <ModalOverlay />
        <ModalContent>
          <Box justifyContent="center">
            <ModalBody>
              <FormLabel textAlign="center" width="100%" height="100%">
                <b>Image Upload</b>
              </FormLabel>

              {imageUrl ? (
                <Box>
                  <Flex justifyContent="left">
                    <CloseButton
                      size="sm"
                      backgroundColor="white"
                      color="red"
                      onClick={() => {
                        imageModalClose();
                      }}
                    />
                    <Text alignSelf="center" color="red" fontSize="12px">
                      Discard image
                    </Text>
                  </Flex>
                  <Image
                    border="1px"
                    borderRadius="4px"
                    borderColor="black"
                    width="100%"
                    src={imageUrl}
                    alt="Project graph"
                  />
                </Box>
              ) : (
                loading && (
                  <Box justifyItems="center">
                    <Skeleton
                      justifyContent="center"
                      variant="rectangular"
                      bottom="8px"
                      height="300"
                      width="300"
                    />
                  </Box>
                )
              )}

              <HStack justifyContent="center">
                {imageUrl && (
                  <>
                    {!changeImage ? (
                      <Box justifyItems="center">
                        <Button
                          top="4px"
                          colorScheme="green"
                          onClick={() => {
                            handleKeyDown(path);
                          }}
                        >
                          Finish Upload
                        </Button>
                      </Box>
                    ) : null}
                  </>
                )}

                {(!imageUrl || changeImage) && (
                  <div justify-content="center" {...getRootProps()}>
                    <input {...getInputProps()} disabled={loading} />
                    <Button colorScheme="blue" isLoading={loading}>
                      Upload {imageUrl && 'new'} image
                    </Button>
                  </div>
                )}
              </HStack>
            </ModalBody>
          </Box>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ImageUploadModal;
