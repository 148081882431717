import React, { useMemo } from 'react';
import dynamic from 'next/dynamic';
import { Flex, Box, Spinner } from '@chakra-ui/react';

import ProjectNavigation from '@/components/ProjectContainer/ProjectNavigation';
import Project from '@projectTypes/Project';
import { useParam } from '@hooks/useParam';

const ProjectInfoEditor = dynamic(
  import('@components/Editor/ProjectInfoEditor'),
  { ssr: false },
);

export const isPageKey = (str?: string): str is 'general' | 'questions' => {
  if (!str) {
    return false;
  }

  return ['general', 'questions'].includes(str);
};

type Props = { project: Project | null };
export const ShowcaseInfoMain: React.FC<Props> = ({ project }) => {
  const page = useParam('page');

  const pageConfig = useMemo(() => {
    if (!page) {
      return undefined;
    }

    if (isPageKey(page)) {
      return { page };
    }

    return undefined;
  }, [page]);

  return (
    <Flex h="100vh" bg="background2" color="white" overflow="auto">
      <Box w="100%">
        <ProjectNavigation outerProps={{ px: 0 }} />
        {project ? (
          <ProjectInfoEditor project={project} embed={pageConfig} />
        ) : (
          <Spinner />
        )}
      </Box>
    </Flex>
  );
};
