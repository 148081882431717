import { useRouter } from 'next/router';

export const getSingleParam = (param: string | string[]) => {
  if (Array.isArray(param)) {
    return param[0];
  }
  return param;
};

export const useParam = (key: string) => {
  const router = useRouter();
  const val = router.query[key];
  return getSingleParam(val);
};
