import React, { useState } from 'react';
import {
  Button,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useToast,
} from '@chakra-ui/react';

import { createNewFile, setNewFileName } from '@slices/fileSlices';
import useDispatch from '@hooks/useDispatch';
import config from '@config';
import Project from '@projectTypes/Project';
import File from '@projectTypes/File';
import { FType } from './FileOptionsMenu';

interface FileCreateModalProps {
  state: boolean;
  path: string;
  folder: boolean;
  createType: string;
  selectedProject: Project;
  currentFile: File;
  rename: boolean;
  onClose: () => void;
}

const FileCreateModal = (props: FileCreateModalProps) => {
  const {
    state,
    path,
    folder,
    createType,
    selectedProject,
    currentFile,
    rename,
    onClose,
  } = props;
  let [createFileName, setCreateFileName] = useState('');
  const [validationState, setValidationState] = useState(false);
  const [fileErrorMsg, setFileErrorMsg] = useState('');
  const dispatch = useDispatch();
  const toast = useToast();

  const createMsg = createType === FType.Folder ? 'Folder' : 'File';
  const renameMsg = rename ? 'Rename' : 'Create';

  const handleKeyDown = () => {
    rename && createFileName.split('.').length > 1
      ? dispatch(
          setNewFileName({
            fileId: currentFile.id,
            name: createFileName,
            ext: createFileName.split('.')[1],
          }),
        )
      : rename
      ? dispatch(
          setNewFileName({
            fileId: currentFile.id,
            name: createFileName + '.' + currentFile.contentType,
            ext: currentFile.contentType,
          }),
        )
      : createType === FType.Folder && rename ?
      dispatch(setNewFileName({
        fileId: currentFile.id,
        name: createFileName,
        ext: currentFile.contentType,
      }))
      : createType === FType.File && !rename
      ? createFileAction(createFileName, path)
      : createFolderAction(createFileName, path);
    onClose();
  };

  function checkAlphaNum(name: string) {
    for (let i = 0, len = name.length; i < len; i++) {
      let code = name.charCodeAt(i);
      if (
        !(code > 47 && code < 58) && // numeric (0-9)
        !(code > 64 && code < 91) && // upper alpha (A-Z)
        !(code > 96 && code < 123) && // lower alpha (a-z)
        !(code === 45 || code === 95)
      ) {
        return false;
      }
    }
    return true;
  }

  function validateFile(name: string) {
    if (!name) {
      setValidationState(false);
      setFileErrorMsg(' ');
      return false;
    }
    const splitName = name.split('.');
    if (!splitName[0]) {
      setFileErrorMsg(config.nameErrorMessage);
      setValidationState(false);
      return false;
    }
    if (!splitName[1]) {
      setFileErrorMsg(config.extensionErrorMessage);
      setValidationState(false);
      return false;
    }
    if (!checkAlphaNum(splitName[0])) {
      setValidationState(false);
      setFileErrorMsg(config.nameErrorMessage);
      return false;
    }

    if (!splitName[1]) {
      setValidationState(false);
      setFileErrorMsg(config.extensionErrorMessage);
      return false;
    }

    if (splitName.length != 2) {
      setValidationState(false);
      setFileErrorMsg(config.surplusCharErrorMessage);
      return false;
    }

    if (!config.allowedFileCreateTypes.includes(splitName[1])) {
      setValidationState(false);
      setFileErrorMsg(config.extensionErrorMessage);
      return false;
    }

    setValidationState(true);
    return true;
  }

  function validateFileRename(name: string) {
    const splitName = name.split('.');
    if (splitName.length > 2 || splitName.length < 1) {
      setValidationState(false);
      setFileErrorMsg(config.surplusCharErrorMessage);
      return false;
    }
    if (splitName[1] === '' && name.includes('.')) {
      setValidationState(false);
      setFileErrorMsg(config.surplusCharErrorMessage);
      return false;
    }
    if (splitName[0]) {
      if (!checkAlphaNum(splitName[0])) {
        setValidationState(false);
        setFileErrorMsg(config.surplusCharErrorMessage);
        return false;
      }
    }
    if (splitName[1]) {
      if (!config.allowedFileCreateTypes.includes(splitName[1])) {
        setValidationState(false);
        setFileErrorMsg(config.extensionErrorMessage);
        return false;
      }
    }
    setValidationState(true);
    return true;
  }

  function validateFolder(name: string) {
    if (!checkAlphaNum(name)) {
      setValidationState(false);
      setFileErrorMsg(config.folderNameErrorMessage);
      return false;
    }
    setValidationState(true);
    return true;
  }

  function validateRename(name: string, fileType: string) {
    if (fileType === FType.File) {
      validateFileRename(name);
    }
    if (fileType === FType.Folder) {
      validateFolder(name);
    }
  }

  async function createFileAction(name: string, newPath: string) {
    if (validateFile(name)) {
      const ext = name.split('.')[1];
      dispatch(
        createNewFile({
          projectId: selectedProject.id,
          content: '',
          name: name,
          contentType: ext,
          path: newPath,
        }),
      );
    } else {
      toast({
        title: fileErrorMsg,
        description:
          'Please provide a file name and the associated extension (e.g. file.html)',
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    }
  }

  async function createFolderAction(name: string, path: string) {
    let crePath;

    if (validateFolder(name)) {
      if (path != '/' && path != '') {
        crePath = path + '/' + name;
      } else {
        crePath = name;
      }

      dispatch(
        createNewFile({
          projectId: selectedProject.id,
          content: '',
          name: config.folderCreationFileName,
          contentType: config.folderCreationFileType,
          path: crePath,
        }),
      );
      return null;
    } else {
      toast({
        title: 'File name invalid',
        description: 'Please provide a valid folder name',
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    }
  }

  return (
    <>
      <Modal isOpen={state} onClose={onClose} size="sm">
        <ModalOverlay />
        <ModalContent
          onKeyDown={(e) => {
            if (validationState && e.key === 'Enter') {
              handleKeyDown();
            }
          }}
        >
          <ModalHeader>
            {renameMsg} {createMsg}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl>
              <FormLabel>Please enter the name of the {createMsg}</FormLabel>
              <Input
                data-cy="createFileInput"
                shadow={'base'}
                isInvalid={!validationState}
                placeholder={createMsg + ' name'}
                onChange={(e) => {
                  setCreateFileName(e.target.value);
                  renameMsg
                    ? validateRename(e.target.value, createType)
                    : createType === FType.File
                    ? validateFileRename(e.target.value)
                    : validateFolder(e.target.value);
                }}
              />
              <Text textColor={'red'} fontSize={'12px'}>
                {' '}
                {!validationState ? fileErrorMsg : ''}{' '}
              </Text>
            </FormControl>
          </ModalBody>

          <ModalFooter>
            <Button
              data-cy="createFileButton"
              isDisabled={!validationState}
              colorScheme={validationState ? 'blue' : 'red'}
              mr={3}
              onClick={() => {
                handleKeyDown();
              }}
            >
              {renameMsg}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default FileCreateModal;
